import styled from 'styled-components'

import footerBG from '../../assets/images/blogfooter.avif'
import footerTabletBG from '../../assets/Footerbg744.png'

export const Footer = styled.footer`
  padding: 2% 10% 0 10%;
  color: white;
  background-image: url(${footerBG});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1280px) {
    background-size: cover;
    padding: 24px 95px 0;
  }
  @media (max-width: 1024px) {
    padding: 42px 62px 0;
  }

  @media (max-width: 1000px) and (min-width: 600px) {
    background-image: url(${footerTabletBG});
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
    padding: 20px 0 0 0;
    margin: 0;
    flex-direction: column;
    background-image: none;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 100px;
    height: 100%;
  }
`

export const SocialContainer = styled.div`
  margin-top: 20px;
  width: auto;

  @media (max-width: 1280px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
`

export const LogoContainer = styled.div`
  @media only screen and (max-width: 600px) {
    display: inline-block;
    text-align: center;
  }
`

export const Logo = styled.img`
  display: block;

  @media only screen and (min-width: 992px) {
    clip-path: none;
  }
`

export const Heading = styled.h3`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  letter-spacing: 0.01em;
  margin: 12px 0 20px 0;
  color: #000000;

  @media (max-width: 744px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  @media only screen and (min-width: 1000px) {
    max-width: 280px;
  }
`

export const Caption = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0 30px;
  border-top: 1px solid #e5e5e5;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 48px;

  letter-spacing: 0.01em;
  text-align: left;

  color: #8b8b8b;
  background-color: #f2f2f2;
  justify-content: space-between;

  @media (max-width: 1280px) {
    padding: 5px 0;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    text-align: center;
    background-image: url(${footerBG});
    background-position: right -40px;
    background-size: 230%;
  }
`

export const Rights = styled.div`
  width: 100%;
`

export const DesignsCount = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 45px;

  @media (max-width: 1280px) {
    padding-right: 20px;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
    padding: 0;
  }
`

export const DesignsCountNumber = styled.span`
  display: inline-block;
  color: black;
  margin-right: 5px;
  font-weight: bold;
`

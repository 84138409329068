import styled from 'styled-components'

export const SocialList = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: -15px;
`

export const SocialItem = styled.li`
  display: inline-block;
  cursor: pointer;
  list-style: none;
  margin: 0 15px 0 0;
  padding: 0;
`

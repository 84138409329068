import React from 'react'
import fbIconImg from '../../assets/blog-social/fb.svg'
import IGIcon from '../../assets/blog-social/ig.svg'
import pinsIconImg from '../../assets/blog-social/pins.svg'
import tiktokIconImg from '../../assets/blog-social/tiktok.svg'

import { SocialList, SocialItem } from './BlogSocialMedia.styles'

const BlogSocialMedia = props => {
  return (
    <SocialList>
      <SocialItem>
        <a
          href={'https://www.instagram.com/decormattersapp/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width={52} height={52} src={IGIcon} alt="Instagram" />
        </a>
      </SocialItem>
      <SocialItem>
        <a
          href={'https://www.pinterest.com/DecorMatters/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width={52} height={52} src={pinsIconImg} alt="Pinterest" />
        </a>
      </SocialItem>

      <SocialItem>
        <a
          href={'https://www.tiktok.com/@decormatters'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width={52} height={52} src={tiktokIconImg} alt="TikTok" />
        </a>
      </SocialItem>
      <SocialItem>
        <a
          href={'https://www.facebook.com/decormattersapp/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width={52} height={52} src={fbIconImg} alt="Facebook" />
        </a>
      </SocialItem>
    </SocialList>
  )
}

export default BlogSocialMedia

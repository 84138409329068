import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import logoImg from '../../assets/dm-logo-long.svg'
import BlogSocialMedia from './BlogSocialMedia'
import FooterLinks from './FooterLinks'

import {
  Footer,
  SocialContainer,
  LogoContainer,
  Logo,
  Heading,
  Caption,
  Rights,
  DesignsCount,
  DesignsCountNumber
} from './Footer.styles'
import { useRouter } from 'next/router'

const BlogFooter = props => {
  const router = useRouter()

  const getCurrentCounter = () => {
    let start = new Date()
    start.setHours(0, 0, 0, 0)
    const startNum =
      1248325 +
      Math.floor((new Date().getTime() / 1000 - 1593475200) / 86400) *
        (86400 / 20)

    return Math.floor(
      startNum +
        Math.floor(new Date().getTime() / 1000 - start.getTime() / 1000) / 20
    )
  }
  const [designCount, setDesignCount] = useState(getCurrentCounter)

  const getYear = () => {
    return new Date().getFullYear()
  }

  useEffect(() => {
    //getTotalDesignCount()
    const timer = setInterval(() => {
      setDesignCount(getCurrentCounter())
    }, 2000)
    return () => clearTimeout(timer)
  }, [designCount])

  if (typeof router.query.inapp !== 'undefined') return null

  return (
    <Footer hideOnMobile={props.hideOnMobile}>
      <SocialContainer>
        <LogoContainer>
          <Link href="/src/create">
            <Logo
              width={157}
              height={30}
              src={logoImg}
              loading="lazy"
              decoding="async"
              alt=""
            />
          </Link>
        </LogoContainer>
        <Heading>Get inspired and unleash your creativity</Heading>
        <BlogSocialMedia />
      </SocialContainer>

      <FooterLinks />
      <Caption>
        <Rights>@ {getYear()} DecorMatters, Inc. All rights reserved.</Rights>
        <DesignsCount>
          <DesignsCountNumber>
            {Number(designCount).toLocaleString()}
          </DesignsCountNumber>{' '}
          Designs Created in DecorMatters
        </DesignsCount>
      </Caption>
    </Footer>
  )
}

export default BlogFooter

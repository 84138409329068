import Link from 'next/link'
import {
  Container,
  LinkList,
  LinkItem,
  LandingItem,
  LandingList,
  More,
  MorePopup,
  ClosePopup
} from './FooterLinks.styles'

const FooterLinks = () => {
  return (
    <>
      <Container>
        <LandingList>
          <LandingItem>
            <a target="_blank" href={'/bathroom-design-app'}>
              Bathroom Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/kitchen-design-app'}>
              Kitchen Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/bedroom-design-app'}>
              Bedroom Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/room-layout-planner-app'}>
              Room Layout Planner
            </a>
          </LandingItem>
        </LandingList>
        <LandingList>
          <LandingItem>
            <a target="_blank" href={'/office-design-app'}>
              Office Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/living-room-design-app'}>
              Living Room Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/dining-room-design-app'}>
              Dining Room Design
            </a>
          </LandingItem>
          <More id="viewMore">
            <a href="#viewMore">View More {'>'}</a>
            <MorePopup className="more-popup">
              <ClosePopup href="#" />
              <a target="_blank" href={'/outdoor-design-app'}>
                Outdoor Design
              </a>
              <a target="_blank" href={'/backyard-design-app'}>
                Backyard Design
              </a>
              <a target="_blank" href={'/garage-design-app'}>
                Garage Design
              </a>
              <a target="_blank" href={'/patio-design-app'}>
                Patio Design
              </a>
            </MorePopup>
          </More>
        </LandingList>
        <LinkList>
          <LinkItem>
            <Link href={'/team'}>Team</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/careers'}>Careers</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/about'}>About</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/blog'}>Blog</Link>
          </LinkItem>
        </LinkList>

        <LinkList>
          <LinkItem>
            <Link href={'/help-center'}>Help Center</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/terms'}>Terms of service</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/policy'}>Privacy Policy</Link>
          </LinkItem>
          <LinkItem>
            <a href="https://apps.apple.com/us/app/decor-matters-design-play/id1168709467">
              Download the iOS App
            </a>
          </LinkItem>
        </LinkList>
      </Container>
    </>
  )
}

export default FooterLinks
